import ResourceDialog from "@/components/utils/ResourceDialog";

export default {
  components: {
    ResourceDialog
  },
  methods: {
    add(initialData) {
      this.$refs.dialog.add(initialData);
    },
    edit(id) {
      this.$refs.dialog.edit(id);
    }
  }
};
