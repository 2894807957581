<template>
  <ElDialog
    v-if="model"
    :visible.sync="visible"
    :title="title"
    width="800px"
    append-to-body
  >
    <ElForm
      ref="form"
      label-width="180px"
      :model="model.data"
      :rules="rules"
      @submit.native.prevent
    >
      <slot :data="model.data" :id="model.id"></slot>
    </ElForm>
    <span slot="footer" class="dialog-footer">
      <div class="flex space-between">
        <div>ID: {{ model.id }}</div>
        <div>
          <template class="flex" v-if="model.id">
            <span class="mr-2" v-if="allowDelete">
              <ElButton
              v-if="!confirmRemove"
              type="danger"
              @click="confirmRemove = true"
              >Sterge</ElButton
            >
            <ElButton
              v-else
              type="danger"
              :loading="loadingRemove"
              @click="handleRemove"
              >Esti Sigur?</ElButton
            >
            </span>
            <span v-if="allowModify">
              <ElButton
                v-if="model.id"
                type="primary"
                :loading="loadingSubmit"
                @click="handleSubmit"
                >Modifica {{ entityName }}</ElButton
              >
            </span>
          </template>
          <ElButton
            v-else
            type="primary"
            :loading="loadingSubmit"
            @click="handleSubmit"
            >Adauga {{ entityName }}</ElButton
          >
        </div>
      </div>
    </span>
  </ElDialog>
</template>

<script>
export default {
  props: {
    rules: {
      type: Object,
      default: () => ({})
    },
    defaults: {
      type: Object,
      default: () => ({})
    },
    allowDelete: {
      type: Boolean,
      default: () => true
    },
    allowModify: {
      type: Boolean,
      default: () => true
    },
    entityName: String,
    collectionName: String
  },
  data() {
    return {
      model: null,
      visible: false,
      loadingSubmit: false,
      loadingRemove: false,
      confirmRemove: false
    };
  },
  computed: {
    title() {
      return this.model.id
        ? `Modifica ${this.entityName}`
        : `Adauga ${this.entityName}`;
    }
  },
  methods: {
    add(initialData = {}) {
      this.model = {
        id: null,
        path: null,
        data: {
          ...this.defaults,
          ...initialData
        }
      };

      this.open();
    },
    async edit(id) {
      const snapshot = await this.$firestore()
        .collection(this.collectionName)
        .doc(id)
        .get();

      this.model = {
        id: snapshot.id,
        path: snapshot.path,
        data: {
          ...this.defaults,
          ...snapshot.data()
        }
      };

      this.open();
    },
    open() {
      this.visible = true;
      this.loadingSubmit = false;
      this.loadingRemove = false;
      this.confirmRemove = false;

      this.$nextTick(() => {
        this.$refs.form.clearValidate();
        // const autofocus = this.$refs.form.$el.querySelector("[autofocus]");
        // if (autofocus) autofocus.select();
      });
    },
    close() {
      this.visible = false;
    },
    async handleSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid) return false;

      if (this.model.id) {
        await this.handleUpdate();
      } else {
        await this.handleInsert();
      }
    },
    async handleInsert() {
      try {
        this.loadingSubmit = true;
        const result = await this.$firestore()
          .collection(this.collectionName)
          .add({
            ...this.model.data,
            createdAt: this.$firestore.FieldValue.serverTimestamp(),
            updatedAt: this.$firestore.FieldValue.serverTimestamp()
          });
        this.$emit("insert", result);
        this.$message.success("Adaugarea a fost efectuata.");
        this.close();
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.");
      } finally {
        this.loadingSubmit = false;
      }
    },
    async handleUpdate() {
      try {
        this.loadingSubmit = true;
        const result = await this.$firestore()
          .collection(this.collectionName)
          .doc(this.model.id)
          .update({
            ...this.model.data,
            updatedAt: this.$firestore.FieldValue.serverTimestamp()
          });
        this.$emit("update", result);
        this.$message.success("Modificarea a fost efectuata.");
        this.close();
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.");
      } finally {
        this.loadingSubmit = false;
      }
    },
    async handleRemove() {
      try {
        this.loadingRemove = true;
        const result = await this.$firestore()
          .collection(this.collectionName)
          .doc(this.model.id)
          .delete();
        this.$emit("remove", result);
        this.$message.success("Stergerea a fost efectuata.");
        this.close();
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.");
      } finally {
        this.loadingRemove = false;
      }
    }
  }
};
</script>
